<template>
  <cw-page
    icon="cancel"
    hero-image="application-error.jpg"
    class="application-not-found"
  >
    <v-row>
      <v-col>
        <p>
          <translate>
            You can start a new loan application through our website.
          </translate>
        </p>
      </v-col>
    </v-row>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="application-not-found__button"
        color="primary"
        href="https://www.saldo.com"
        @click="$eventLogger.clickEvent($event)"
      >
        <translate>
          Apply for a loan from Saldo
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
export default {
  name: 'CwApplicationNotFound',
};
</script>
